import React from 'react';
import { RichText } from '../richTextOptions';
import { connect } from 'react-redux';
import { getIsPreviewStatus } from '../../state/app';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStaticQuery, graphql } from 'gatsby';
const SectionTextWithArticleCardSlide = (props) => {
     const { textBanner, articleCardSlide, backgroundImage, isPreview } = props;
     const backgroundImageUrl = isPreview ? backgroundImage.fields.file.url : backgroundImage.file.url;

     const GetdataVetsArticles = useStaticQuery(
          graphql`
               query {
                    allContentfulVetsArticle(sort: { fields: createdAt, order: DESC }, limit: 5) {
                         nodes {
                              ...ContentfulVetsArticleFields
                         }
                    }
               }
          `
     );

     const DataVetsArticles = GetdataVetsArticles.allContentfulVetsArticle.nodes;

     const vetsArticlesNotCardSlide = DataVetsArticles.filter((article) =>
               !articleCardSlide?.some((pin) => {
                    if (isPreview) {
                         return pin.fields.slug === article.slug;
                    }
                    return pin.slug === article.slug;
               })
     ).slice(0,2);

     const vetsArticles = [...vetsArticlesNotCardSlide, ...articleCardSlide];

     return (
          <section className="vets-banner" style={{ backgroundImage: `url(${backgroundImageUrl})`, '--section-background-image': `url(${backgroundImageUrl})` }}>
               <div className="container">
                    <div className="row">
                         <div className="col-lg-6 vets-banner-left">
                              <div className="vets-banner-box">
                                   <RichText data={isPreview ? textBanner.fields.richText : textBanner.richText} />
                              </div>
                         </div>
                         <div className="col-lg-6 vets-banner-right">
                              <div className="vets-banner-slides">
                                   <div className="is-mobile">
                                        <Swiper
                                             loop={true}
                                             spaceBetween={10}
                                             breakpoints={{
                                                  0: {
                                                       slidesPerView: 1.3,
                                                       spaceBetween: 10
                                                  },
                                                  414: {
                                                       slidesPerView: 1.5,
                                                       spaceBetween: 10
                                                  },
                                                  575: {
                                                       slidesPerView: 2.3,
                                                       spaceBetween: 10
                                                  },
                                                  768: {
                                                       slidesPerView: 2.5,
                                                       spaceBetween: 10
                                                  }
                                             }}
                                        >
                                             {vetsArticles.map((article, index) => {
                                                  const newArticle = isPreview ? article.fields : article;
                                                  const featuredImage = isPreview ? newArticle.featuredImage.fields.file.url : newArticle.featuredImage.file.url;
                                                  let articleSlug = '';
                                                  let contentTopicsName = '';
                                                  if (newArticle.vetsContentType) {
                                                       newArticle.vetsContentType.forEach((contentType, index) => {
                                                            if (index < newArticle.vetsContentType.length - 1) {
                                                                 contentTopicsName += contentType.contentTypeName + ', ';
                                                            } else {
                                                                 contentTopicsName += contentType.contentTypeName;
                                                            }
                                                       });
                                                  } else if (newArticle.vetsTopics) {
                                                       newArticle.vetsTopics.forEach((contentType, index) => {
                                                            if (index < newArticle.vetsTopics.length - 1) {
                                                                 contentTopicsName += contentType.topicsName + ', ';
                                                            } else {
                                                                 contentTopicsName += contentType.topicsName;
                                                            }
                                                       });
                                                  }
                                                  if (isPreview) {
                                                       articleSlug = '/' + newArticle.vetsCategory.fields.slug + '/' + newArticle.slug;
                                                  } else {
                                                       articleSlug = '/' + newArticle.vetsCategory.slug + '/' + newArticle.slug;
                                                  }

                                                  return (
                                                       <SwiperSlide key={index}>
                                                            <a href={articleSlug} target="_self">
                                                                 <div className="vets-banner-slides-card" style={{ backgroundImage: `url(${featuredImage})` }}>
                                                                      <h4>{contentTopicsName}</h4>
                                                                      <p>
                                                                           <a href={articleSlug} target="_self">
                                                                                {newArticle.articleTitle}
                                                                           </a>
                                                                      </p>
                                                                 </div>
                                                            </a>
                                                       </SwiperSlide>
                                                  );
                                             })}
                                        </Swiper>
                                   </div>
                                   <div className="is-desktop">
                                        <div className="row">
                                             {vetsArticles.map((article, index) => {
                                                  const newArticle = isPreview ? article.fields : article;
                                                  const featuredImage = isPreview ? newArticle.featuredImage.fields.file.url : newArticle.featuredImage.file.url;
                                                  let articleSlug = '';
                                                  let contentTopicsName = '';
                                                  if (newArticle.vetsContentType) {
                                                       newArticle.vetsContentType.forEach((contentType, index) => {
                                                            if (index < newArticle.vetsContentType.length - 1) {
                                                                 contentTopicsName += contentType.contentTypeName + ', ';
                                                            } else {
                                                                 contentTopicsName += contentType.contentTypeName;
                                                            }
                                                       });
                                                  } else if (newArticle.vetsTopics) {
                                                       newArticle.vetsTopics.forEach((contentType, index) => {
                                                            if (index < newArticle.vetsTopics.length - 1) {
                                                                 contentTopicsName += contentType.topicsName + ', ';
                                                            } else {
                                                                 contentTopicsName += contentType.topicsName;
                                                            }
                                                       });
                                                  }
                                                  if (isPreview) {
                                                       articleSlug =  '/' + newArticle.vetsCategory.fields.slug + '/' + newArticle.slug;
                                                  } else {
                                                       articleSlug =  '/' + newArticle.vetsCategory.slug + '/' + newArticle.slug;
                                                  }

                                                  return (
                                                       <div className="col-md-6" key={index}>
                                                            <a href={articleSlug} target="_self">
                                                                 <div className="vets-banner-slides-card" style={{ backgroundImage: `url(${featuredImage})` }}>
                                                                      <h4>{contentTopicsName}</h4>
                                                                      <p>
                                                                           <a href={articleSlug} target="_self">
                                                                                {newArticle.articleTitle}
                                                                           </a>
                                                                      </p>
                                                                 </div>
                                                            </a>
                                                       </div>
                                                  );
                                             })}
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </section>
     );
};

SectionTextWithArticleCardSlide.propTypes = {
     isPreview: PropTypes.bool
};

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state)
     };
};

const ContentfulSectionTextWithArticleCardSlide = connect(mapStateToProps)(SectionTextWithArticleCardSlide);

export default ContentfulSectionTextWithArticleCardSlide;
